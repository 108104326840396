<template>
  <div class="number-on-circle" :class="{ 'fix-position-on-desktop': mdAndUp, small: small }">
    {{ number }}
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()

withDefaults(
  defineProps<{
    number: string
    small?: boolean
  }>(),
  {
    small: false,
  },
)
</script>

<style scoped lang="scss">
.number-on-circle {
  display: inline-flex !important;
  aspect-ratio: 1 / 1 !important;

  justify-content: center;
  align-items: center;

  background-color: rgb(var(--v-theme-secondary));
  color: white;

  height: 49px;
  margin: 0;
  margin-right: 0.8rem;
  bottom: 10px;
  font-size: 33px;
  font-weight: 800;
  line-height: 50.6px;
  letter-spacing: -0.01em;

  border-radius: 50%;

  &.small {
    transform: scale(0.6);
  }
}

.fix-position-on-desktop {
  transform: translateY(-4px);
}
</style>
